(function(){
    $(function(){
        if($('#module-thecheckout-order').length < 1) {
            return;
        }
        
        $(document).on('mouseenter','[for="input_gift"] span', function() {
           
            $('img.gift-preview').addClass('is-active');
        });

        $(document).on('mouseout','[for="input_gift"] span', function() {
            $('img.gift-preview').removeClass('is-active');
        });

        $(window).on('scroll', function(){
            if($('[for="input_gift"]').offset().top - window.pageYOffset > $('.gift-preview').outerHeight()) {
                $('.gift-preview').addClass('display-top');
                $('.gift-preview').removeClass('display-bottom');
            }else {
                $('.gift-preview').removeClass('display-top');
                $('.gift-preview').addClass('display-bottom');
            }
        });
    })
})();