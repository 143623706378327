import '../scss/coriss.scss';
// import 'jquery-zoom';

import './base/common';
import './components/header';
import './components/home';
import './components/filters';
import './components/product-gallery';
import './components/product-comments';
import './components/product-not-available';

import './components/product-not-available';
import './components/category/products-sort';
import './components/home-slider';

import './views/category';
import './views/checkout';

import './modules/furgonetka';

