(function($){
    $(document).ready(function(){
        /* Check if is thecheckout module page */
        if($('#module-thecheckout-order').length < 1) {
            return;
        }

        /* Check if isset thecheckout variable for validation injection */
        if(tc_confirmOrderValidations === undefined || !tc_confirmOrderValidations) {
            console.warn('NO VARIABLE');
            return;
        }

        /* Validate shipping */
        tc_confirmOrderValidations.furgonetka_paczkomaty = function() {
            const machineInput = $('[id^="furgonetka-delivery-"');

            // If no machineInput, then there is no machine self pickup delivery option
            // Then we return true because this validation is not apropriate
            if(machineInput === undefined || machineInput.length < 1) {
                return true;
            }
            
            let furgonetkaDeliveryId = machineInput.prop('id').toString().replace('furgonetka-delivery-', '');

            furgonetkaDeliveryId = parseInt(furgonetkaDeliveryId);

            const machineDeliveryMethod = $('input#delivery_option_' + furgonetkaDeliveryId);

            // If furgonetka has machine self pickup delivery option but PS delivery method not exists
            // We return true, because self pickup delivery is not able (so validation is not needed)
            if(machineDeliveryMethod === undefined || machineDeliveryMethod.length < 1) {
                return true;
            }

            // If machine delivery method is not checked, there is no reason to validate it, so return true
            if(machineDeliveryMethod.prop('checked') !== true) {
                return true;
            }

            // Machine delivery is set - get the input
            const furgonetkaMachine = $('input#furgonetka-machine-' + furgonetkaDeliveryId);

            // Machine delivery is set, but user didn't choosed yet - validation fails, return false
            if(furgonetkaMachine === undefined || furgonetkaMachine.length < 1) {
                showValidationErrors();
                return false;
            }

            const furgonetkaMachineValue = furgonetkaMachine.val();

            // Machine delivery is set, machine is set, but no number passed (something wrong with furgonetka module JS operations), so return false
            if(!furgonetkaMachineValue || furgonetkaMachineValue.trim() == '') {
                showValidationErrors();
                return false;
            }

            /* Everything is ok! :) */
            return true;
        };

        function showValidationErrors() {
            /* Remove existing errors */
            hideValidationErrors();

            const shipping = $('#thecheckout-shipping');

            /* If you want to make it multilingual, register string to translate in PHP or TPL and pass it there somehow */
            const msg = "Nie wybrano punktu odbioru";
            
            shipping.prepend('<div class="custom-error-msg">' + msg + '</div>');

            $('html, body').animate({
                scrollTop: $("#thecheckout-shipping").offset().top - 100
            }, 500);
        }

        function hideValidationErrors() {
            const shipping = $('#thecheckout-shipping');
            const errors = shipping.find('.custom-error-msg');
            if(errors && errors.length > 0) {
                errors.remove();
            }
        }

        $(document).on('click', '#thecheckout-shipping', function(){
            hideValidationErrors();
        });

    });
})(jQuery);