// (function($){
//     if( $('.home-slider').length < 1) {
//         return;
//     }
//     $('.home-slider').slick({
//         infinite: true,
//         slidesToShow: 1,
//         slidesToScroll: 1,	
//         autoplaySpeed: 3000,
//         pauseOnHover: true,
//         infinite: true,
//         arrows: false,
//         autoplay: true,
        
//     });
// })(jQuery);