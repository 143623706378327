(function($){
    window.addEventListener('DOMContentLoaded', () => {
        toggleBlock();
        prestashop.on('updatedProduct', toggleBlock);
    })

    function toggleBlock() {
        const btn = $('button.add-to-cart');
        const block = $('.product-not-available');

        if(!btn || !block) return;

        block.toggle(btn.prop('disabled'));
    }
})(jQuery);