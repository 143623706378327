(function () {
    "use strict";
    $(document).ready(function () {
        function isMobile() {
            try {
                document.createEvent("TouchEvent");
                return true;
            } catch (e) {
                return false;
            }
        }
        if (!isMobile()) {
            $(document).on({
                mouseenter: function () {
                    $(this).addClass('open').find('dropdown-toggle').attr('aria-expanded', "true");
                },
                mouseleave: function () {
                    $(this).removeClass('open').find('dropdown-toggle').attr('aria-expanded', "false");
                }
            }, '#search_filters .dropdown');
        }

        // $(window).on('load resize', function() {
        //     if (window.innerWidth <= 991) {
        //         $('.facet').removeClass('dropdown');
        //         $('.facet_ul').removeClass('dropdown-menu').addClass('collapse');
        //     }
        //     else {
        //         $('.facet').addClass('dropdown');
        //         $('.facet_ul').removeClass('collapse').addClass('dropdown-menu');
        //     }
        // });

    });
})(jQuery);