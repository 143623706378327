(function () {
    window.addEventListener('DOMContentLoaded', () => {
        
        function initGallery() {
            if (!document.getElementById('product')) return;
            const items = [];

            /* Fetch images */
            const images = document.querySelectorAll('.gallery-image img');

            for (const image of images) {
                items.push({
                    src: image.getAttribute('data-image-large-src').toString(),
                    w: Number.parseInt(image.getAttribute('data-image-large-width')),
                    h: Number.parseInt(image.getAttribute('data-image-large-height')),
                });
            }

            const galleryModal = document.querySelectorAll('.pswp')[0];

            const options = {
                preload: [1, 1],
                index: 0,
                shareEl: false,
                counterEl: false,
                bgOpacity: 0.9,
                closeOnScroll: true,
                closeOnVerticalDrag: false
            };

            function openGallery(index) {
                const gallery = new PhotoSwipe(galleryModal, PhotoSwipeUI_Default, items, {
                    ...options,
                    index
                });

                gallery.init();
            }

            const thumbs = $('.gallery-image img');

            /* Fix with unbind for invalid close method */
            thumbs.unbind('click').click(function () {
                const index = Number.parseInt($(this).data('index')) + 1;
                openGallery(index);
            });
        }

        function initSlider() {
            if ($('#product').length < 1) {
                return;
            }

            const slickGallery = $('.gallery-image').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                infinite: false,
                autoplay: false,
                prevArrow: '<button type="button" class="slick-prev"><i class="material-icons">chevron_left</i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="material-icons">chevron_right</i></button>',
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        dots: true
                    }
                }]
            });

            var slickThumbs = document.querySelectorAll('.gallery-thumbnails img');

            // Change slide when thumbnail clicked
            slickThumbs.forEach(function (image) {
                image.addEventListener('click', function () {
                    slickGallery.slick('slickGoTo', Number.parseInt(image.getAttribute('data-index')));
                });
            });

            // update active thumbnail
            slickGallery.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                $('.gallery-thumbnails img[data-index=' + nextSlide + ']').parent()
                    .addClass('is-active')
                    .siblings().removeClass('is-active');
            });

            $('.gallery-thumbnails').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                vertical: true,
                verticalSwiping: true,
                arrows: true,
                focusOnSelect: true,
                infinite: false,
                asNavFor: '.gallery-image',
                prevArrow: '<button type="button" class="slick-up" ><i class="material-icons">chevron_left</i></button>',
                nextArrow: '<button type="button" class="slick-up"><i class="material-icons down">chevron_right</i></button>',
                responsive: [{
                    breakpoint: 767,
                    settings: "unslick"
                }]
            });
        }
        
        initGallery();
        initSlider();

        prestashop.on('updatedProduct', initGallery);
        prestashop.on('updatedProduct', initSlider);

    });
})();