class CategoryView {
    constructor() {
        window.addEventListener('DOMContentLoaded', () => {
            this.scrollOnPaginationClick();
        });
    }
    
    /* Scroll do góry po kliknieciu w link paginacji */
    scrollOnPaginationClick() {
        prestashop.on('updateFacets', () => {
            this.smoothscroll();
        });
    } 

    /* Mechanika scrolla z animacją */
    smoothscroll() {
        setTimeout(() => {
            $("html, body").animate({ scrollTop: 0 }, "slow");
        }, 500);
        
    }
    
}

new CategoryView();