(function($){
    "use strict";

    window.addEventListener('DOMContentLoaded', () => {

        if(!menuShoudBeStatic()) {
            const body = document.body;
            const header = document.getElementById('header');
            const headerBanner = document.querySelector('.header-banner');
            const headerTop = document.querySelector('.header-top');
            const mobileMenu = document.querySelector('#mobile_top_menu_wrapper');
    
            body.style.paddingTop = `${header.offsetHeight}px`;
    
            updateHeader();
    
            window.addEventListener('scroll', () => {
                updateHeader();
            }); 
    
            window.addEventListener('resize', () => {
                body.style.paddingTop = `${header.offsetHeight}px`;
                updateHeader();
            }); 
    
            function updateHeader() {
                if($('#cart').length > 0) {
                    console.log('warunek 1');
                    return;
                }
                const customModal = document.querySelectorAll('.custom-modal');
                const offset = headerTop.offsetHeight + headerBanner.offsetHeight;
                
                if(window.scrollY > 0) {
                    header.style.transform = `translateY(-${offset}px)`;
                    body.classList.add('is-scrolled');
    
                    /* RESET */
                    mobileMenu.style.top = `${offset}px`;
                    
                    for (const modal of customModal) {
                        modal.style.top = `${offset}px`;
                    }
                    
                } else {
                    header.style.transform = 'translateY(0px)';
                    body.classList.remove('is-scrolled');
    
                    /* RESET */
                    mobileMenu.style.top = '0';
                    for (const modal of customModal) {
                        modal.style.top = '0';
                    }
                }
            }
        }
        

        function menuShoudBeStatic() {
            return $('#cart').length > 0 || $('#module-thecheckout-order').length > 0;
        }

        /* MENU MOBILE */
        $('#menu-icon').on('click', function(){
            $('#mobile_top_menu_wrapper').addClass('is-active');
        });

        $('.menu-close, .menu-close > i').on('click', function(){
            $('#mobile_top_menu_wrapper').removeClass('is-active');
        });


    });
})(jQuery);